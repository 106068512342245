.invite-welcome-container {
    background: #396afc;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 5rem 2rem;
    color: white;
    box-sizing: border-box;
}

.invite-welcome-content > h1 {
    margin: 0;
    font-size: 2rem;
}

@media (max-width: 1024px) {
    .invite-welcome-container {
        padding: 2rem;
    }
}