.question-content {
    margin-left: calc(33px + 15px);
    margin-top: 1rem;
}

@media (max-width: 900px) {
    .question-content {
        margin-left: 0;
    }
}

.number-toggle-button {
    display: inline-block;
    font-size: 26px;
    margin-top: 8px;
    margin-left: 10px;
}

.number-toggle-button:hover {
    cursor: pointer;
}