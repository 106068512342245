.tax-item-container {
    display: grid;
    grid-template-columns: 48% 48%;
    row-gap: 1rem;
    justify-content: space-between;
}

.tax-section-container {
    display: grid;
    row-gap: 1rem;
}

.tax-text {
    font-size: 0.75rem;
    margin: 0.25rem 0;
    text-align: left;
}

.tax-item {
    --bar-width: 4px;
}

.tax-item-number {
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    margin: 0.25rem 0;
}

.tax-item-row {
    display: grid;
    justify-content: start;
    column-gap: 0.75rem;
    grid-auto-flow: column;
    align-items: center;
}

.tax-blue-bar {
    width: var(--bar-width);
    height: 100%;
    background-color: var(--dashboard-blue-purple);
}

.tax-gray-bar {
    width: var(--bar-width);
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.tax-total-container {
    text-align: left;
}

.state-tax-mobile {
    display: flex;
    font-size: 0.875rem;
    margin: 1rem 0;
}

.state-tax-header-container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
}

.state-tax-header {
    flex: 1 1 33%;
}

.hide-tax {
    display: none;
}

.tax-numbers-container {
    flex: 1 1;
    text-align: right;
    padding-right: 5vw;
}