.option {
    margin-top: 6px;
    width: calc(100% * 0.33);
    min-width: 200px;
    --option-height: 45px;
    line-height: var(--option-height);
    padding: 3px 10px;
    background-color: var(--background-color-faded);
    color: white;
    border: 2px solid var(--dark-background-color);
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option:hover {
    cursor: pointer;
    opacity: 0.8;
}

.option-active {
    border: 2px solid white !important;
}

.option-letter {
    background-color: var(--dark-background-color-faded);
    --option-letter-size: calc(var(--option-height) * .7);
    width: var(--option-letter-size);
    line-height: var(--option-letter-size);
    margin-right: 10px;
    text-align: center;
    border-radius: 6px;
}

.option-letter-active {
    background-color: white !important;
    color: var(--dark-background-color-faded);
}

.option-check {
    float: right;
    height: var(--option-height);
}

@media (max-width: 900px) {
    .option {
        width: 100%;
    }
}