.progress-container {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.progress-control-container {
    display: flex;
    align-items: center;
    transition: 1s;
}

.progress-container-translate-left {
    transform: translateX(-38vw);
}

.completed-text { 
    font-size: 1.3vw;
    font-weight: bold;
    margin-top: 0px;
}

.bar-background {
    background-color: var(--progress-bar-background);
    height: 12px;
    width: 20vw; 
    border-radius: 8px;
    margin-right: 15px;
}

.bar-foreground {
    background-color: white;
    height: 100%;
    width: 10%;
    border-radius: 8px;
    transition: width 1s;
}

.arrow {
    color: var(--dark-background-color);
    background-color: var(--cloud-white);
    padding: 8px;
    border-radius: 6px;
    margin: 0 3px;
    border: none;
}

.arrow:focus {
    outline: none;
}

.arrow:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.2s;
    background-color: #dce0e0;
}

.arrow-icon {
    font-size: 2vw;
}