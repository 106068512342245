.menu-icon-container {
  --menu-button-height: calc(var(--menu-width) - 30px) !important;
  display: flex !important;
  align-items: center !important;
  height: var(--menu-button-height) !important;
  line-height: var(--menu-button-height) !important;
  padding: 1rem !important;
  margin: 1rem !important;
  border-radius: var(--dashboard-border-radius) !important;
}

.menu-icon-container:hover {
    cursor: pointer !important;
    background-color: rgba(0, 0, 0, 0.025) !important;
}

.menu-icon-active {
    background-color: var(--dashboard-blue) !important;
    color: white;
}

.menu-button-icon-disabled {
  color: gray !important;
}

.menu-icon-active-yellow {
    background-color: var(--yellow) !important;
}

.menu-button {
    display: flex;
    width: 80%;
    color: black;
    font-weight: bold;
    align-items: center;
    font-size: 22px;
    justify-content: center;
    margin: 0 auto;
}

.menu-button-icon {
    color: black;
}

.menu-button-icon-active {
    color: white !important;
}