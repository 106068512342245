.forecasting-change-container {
    background-color: var(--dashboard-ice-white);
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-sizing: border-box;
    border-radius: var(--dashboard-border-radius);
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.forecasting-change-container > h4 {
    max-width: 60%;
}

.forecasting-change-before-after {
    display: flex;
    flex-direction: column;
    text-align: right;
}

.forecasting-change-before-after > p {
    margin: 5px 0;
}

.forecasting-change-delete {
    position: absolute;
    top: -10px;
    left: -5px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    width: 30px;
    background-color: #e74c3c;
    color: white;
    cursor: pointer;
}

.forecasting-activate-button {
    width: 100%;
    height: 60px;
    font-weight: bold;
    margin: 30px 0;
    border-radius: var(--dashboard-border-radius);
    background: var(--dashboard-blue);  /* fallback for old browsers */
    color: white;
    font-size: 1rem;
    border: none;
    cursor: pointer;
}

.forecasting-disable-button {
    color: #e74c3c;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin-bottom: 20px;
}