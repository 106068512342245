.org-user-form {
    background-color: white;
    text-align: center;
    padding: 50px 0;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    width: 50%;
    min-width: 800px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-top: 10px solid #396afc;  /* fallback for old browsers */
    border-top: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    border-top: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.org-user-form-title {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 1.7rem;
    width: 100%;
    text-align: center;
}

.org-user-form-input {
    margin: 1rem;
    padding: 0 1.5rem;
    width: 100%;
}

.org-user-form-input > label {
    display: block;
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 5px 0;
}

.org-user-form-input > input {
    padding: 12px 15px;
    width: 100%;
    background-color: var(--light-white);
    border: none;
    border-radius: 10px;
}

.org-user-form-input > input:focus {
    outline: none;
}

.org-user-form-action-button {
    background: #396afc;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    border: none;
    width: 50%;
    padding: 20px 0;
    font-weight: bold;
    border-radius: 10px;
    margin: 15px 0;
    margin-top: 40px;
}

.org-user-form-action-button:hover {
    transform: scale(1.06);
    transition: 0.2s;
    cursor: pointer;
}

.org-user-form-back-button {
    display: block;
    margin: 0 auto;
    font-weight: 700;
    padding: 8px 10px;
}

.org-user-form-back-button:hover {
    cursor: pointer;
    transform: scale(1.06);
    transition: 0.2s;
}

.org-user-form-back-button > svg {
    margin-right: 10px;
}

.org-user-form-input-error {
    border: 1.5px solid #e74c3c !important;
}

.org-user-form-error-message {
    color: #e74c3c;
    text-align: left;
    font-size: 0.8rem;
    margin: 8px 0;
}