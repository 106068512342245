.create-account-submit {
    background: #8E2DE2;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4A00E0, #8E2DE2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
    width: 100%;
    border: none;
    height: 50px;
    border-radius: 6px;
    font-weight: 700;
    margin-top: 20px;
}

.create-account-submit:hover {
    cursor: pointer;
}