.submit-button {
    display: inline-block;
    border-radius: 10px;
    border: 0;
    padding: 10px 16px;
    font-weight: 800;
    min-width: 10%;
    font-size: 14px;
    color: var(--dark-background-color);
    background-color: var(--cloud-white);
    margin-top: 20px;
}

.submit-button:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.4s;
    background-color: #dce0e0;
}

.submit-button:focus {
    outline: none;
}

.submit-button-text {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
    font-size: 0.9rem;
}