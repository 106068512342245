.page-loader-background-container {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-loader-container {
    background-color: white;
    padding: 30px 50px; 
    border-radius: 5px;
    color: black;
    display: grid;
    row-gap: 25px;
    padding-bottom: 60px;
}