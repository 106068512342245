.number-description {
    margin-top: -2px;
    margin-right: 10px;
}

.number-description-label {
    font-size: 12px;
    font-weight: bold;
}

.number-description-input {
    display: block;
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid white;
    font-size: 20px;
    color: white;
    width: 25vw;
    font-weight: bold;
}

.number-description-input:focus {
    outline: none;
}