.show-section {
    display: block;
    margin: 1rem;
}

.show-header {
    font-size: 1.25rem;
    font-weight: bolder;
    margin: 1.25rem 0.25rem;
    color: var(--dashboard-blue-purple)
}

.hide-section {
    display: none;
}

.category-buttons {
    margin: 0.25rem;
}

.question-box {
    margin: 1rem 0.25rem;
}