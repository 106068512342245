:root {
    --red: #e74c3c;
    --electric-blue: #3498db;
    --dark-blue: #34495e;
    --faded-blue: #2673a6;
    --cloud-white: #ecf0f1;
    --electric-blue-faded: #4ca5e0;
    --heavy-blue: #4543FA;
    --purple: #529FE3;
    --dark-blue-faded: #2673a6;
    --emerald-green: #2ecc71;
    --emerald-green-faded: #56cc88;
    --blue-purple: #5579e4;
    --dark-green: #208049;
    --light-white: #f1f3f7;
    --admin-text: #b0c0d3;
    --admin-background: #f5f8fb;
    --admin-light-blue: #7AB8E1;
    --admin-blue: #3498DB;
    --yellow: #f1c40f;
    --admin-faded-blue: #2875A8;
    --admin-dark-blue: #16405C;
    --admin-purple: #6f83c9;
    --admin-light-purple: #dbe1f6;
    --admin-dark-purple: #8D6BD6;

    --dashboard-ice-white: #F6F9FC;
    --dashboard-blue: #4543FA;
    --dashboard-blue-purple: #5579e4;
    --dashboard-light-purple: rgba(85, 121, 228, 0.1);
    --dashboard-font-small: 0.75rem;

    --dashboard-border-radius: 10px;
    --dashboard-widget-padding: 1.5rem;

    --screen-width-small: 600px;
    --screen-width-md: 900px;
    --screen-width-lg: 1200px;
    --screen-width-xl: 1536px;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #f0f0f0;
}

.margin-right {
    margin-right: 20px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-space-between {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}

.flex-space-between-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-default {
    display: flex;
    align-items: center;
}

#background-red {
    background-color: var(--red);
    background: var(--red) !important; 
}

.margin-top {
    margin-top: 30px;
}

#small-font {
    font-size: 12px !important;
}

.hide-element {
    display: none;
}

.vertical-divider {
    display: inline-block;
    color: black;
    font-size: 18px;
    margin: 0 8px;
}