.overview-graph-container {
    background-color: white;
    box-sizing: border-box;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px;
    border-radius: 10px;
}

.overview-graph-chart {
    height: 20vh;
    width: 40vw;
    margin-left: -10px;
}

.overview-graph-chart {
    margin-top: 10px;
}

.overview-graph-x-label {
    font-size: 1rem;
    text-align: center;
    margin: 5px 0;
}