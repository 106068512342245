.Survey {
  padding: 12vh 8vw;
  margin: 0;
  min-height: 100vh;
  color: white;
}

.blue-bg {
  --background-color: var(--electric-blue);
  --background-color-faded: var(--electric-blue-faded);
  --dark-background-color: var(--dark-blue);
  --dark-background-color-faded: var(--dark-blue-faded);
  --progress-bar-background: #2673a6;
  background-color: var(--background-color);
}

.green-bg {
  --background-color: var(--emerald-green);
  --background-color-faded: var(--emerald-green-faded);
  --dark-background-color: var(--dark-green);
  --dark-background-color-faded: var(--dark-green);
  --progress-bar-background: var(--dark-green);
  background-color: var(--background-color);
}

.page-title {
  color: white;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10vh;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  right: 0;
}

.survey-overview-return-button {
  background-color: var(--dark-background-color);
  position: absolute;
  top: 5vh;
  cursor: pointer;
  right: 10px;
  text-align: center;
  width: 200px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 40px;
}