.question-label {
    display: flex;
    font-size: 22px;
    align-items: flex-start;
}

.question-number {
    text-align: center;
    background-color: var(--dark-background-color);
    font-size: 16px;
    margin: 0 15px;
    margin-left: 0px;
    display: inline-block;
    line-height: 30px;
    height: 30px;
    width: 33px;
    border-radius: 50%;
}

.question-description {
    display: block;
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
    margin-top: 10px;
    white-space: break-spaces;
}