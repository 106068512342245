.progress-bar {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    border-radius: var(--dashboard-border-radius);
    background-color: var(--dashboard-ice-white);
    font-weight: bold;
    cursor: pointer;
}

.progress-bar-link {
    color: var(--dashboard-blue-purple);
}

@media (max-width: 900px) {
    .progress-bar {
      font-size: 0.7rem;
      padding: 0.5rem;
    }
}

.widget-title {
    font-style: normal;
    text-align: left;
    font-weight: bold;
    font-size: 1.5rem;
    color: black;
    margin: 0;
}

.widget-light-text {
    font-style: normal;
    font-size: var(--dashboard-font-small);
    font-weight: 300;
    letter-spacing: 0.165em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    text-align: left;
}

.widget-icon-button.MuiIconButton-root {
    padding: 0.25rem 0.5rem;
}

.widget-bold-small {
    font-style: normal;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 16px;
    color: black;
    text-align: left;
    margin-left: 0.5rem;
}

@media (max-width: 900px) {
    .widget-bold-small {
        padding-bottom: 0.75rem;
    }
}

.grid-column-2 {
    display: grid;
    row-gap: 20px;
    align-items: start;
}

.overview-widgets-container {
    --column-gap: calc(100% - 54% - 44%);
    display: grid;
    grid-template-columns: 54% 44%;
    justify-content: space-between;
    align-self: start;
    width: 100%;
    row-gap: 25px;
    margin-bottom: 3vh;
}

#overview-widgets-container-forecast .widget-container-full,
#overview-widgets-container-forecast .widget-container {
    background-color: rgba(241,196,15,0.125) !important;
}

.widget-container {
    flex: 1 1 50%;
    background-color: var(--dashboard-ice-white);
    text-align: center;
    padding: var(--dashboard-widget-padding);
    border-radius: var(--dashboard-border-radius);
    color: black;
}

.widget-container-full {
    grid-column-start: 0;
    grid-column-end: span 2;
}

.widget-header-container {
    margin: 0;
    display: flex;
    align-items: center;
    margin-bottom: var(--dashboard-widget-padding);
    justify-content: space-between;
}

.tax-container {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 900px) {
    .widget-header-container  {
      flex-direction: column;
    }

    .tax-container {
        flex-direction: column;
    }
}

.widget-configuration-button-container {
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    font-size: var(--dashboard-font-small);
    margin-bottom: 0.75rem;
}

.widget-configuration-button {
    background-color: white;
    border: 0;
    border-radius: var(--dashboard-border-radius);
    cursor: pointer;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    text-transform: none;
}

.widget-configuration-button > span {
    margin: 0;
}


.widget-configuration-button:hover {
    transform: scale(1.02);
}

.widget-configuration-button-info {
    width: 32.99px !important;
    cursor: auto !important;
}

.overview-header-button-outline {
    background: white;  /* fallback for old browsers */
    color: #79747E;
    cursor: pointer;
    padding: .5rem .75rem;
    font-size: .75rem;
    font-weight: bold;
    border-radius: var(--dashboard-border-radius);
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    text-align: left;
}

.hide-widget {
    display: none;
}

.widget-container > * .MuiSwitch-root {
    margin-right: -12px !important;
}

.widget-container > * .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: var(--dashboard-light-purple) !important;
}

.sick-leave-switch {
    display: flex;
    align-items: center;
}

.sick-leave-switch > p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    margin: 0;
}

.widget-header-select.MuiButtonBase-root {
    display: block;
}

@media only screen and (max-width: 900px) {
    .overview-widgets-container {
        display: block;
    }
    .overview-widgets-left-container {
        width: 100%;
        margin-right: 0;
    }
    .overview-widgets-right-container {
        margin-top: 20px;
    }
}

.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.space-between-flex-start {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

/* SLIDER */

.overview-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    margin-top: var(--dashboard-widget-padding);
}

.overview-slider-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

/* BREAKDOWN TAB */

.details-header {
    text-align: left;
}

.breakdown-header {
    margin-bottom: 0.75rem;
    background-color: var(--dashboard-blue-purple);
    font-weight: 800;
    color: white;
    text-align: center;
    padding: var(--dashboard-widget-padding);
    border-radius: var(--dashboard-border-radius);
}