.overview-daily-number {
    margin: var(--dashboard-widget-padding) 0;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: var(--dashboard-blue-purple);
    text-align: left;
}

.overview-daily-description {
    font-size: 14px;
    width: 80%;
    margin-bottom: 10px;
    font-weight: normal;
    text-align: left;
    margin: 0;
}

.overview-daily-unit {
    font-weight: bold;
}