.sick-leave-section-container {
    display: grid;
    row-gap: 45px;
}

.sick-leave-input-container {
    display: grid;
    row-gap: 15px;
}

.sick-leave-item-container {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 32% 32% 32%;
    justify-content: space-between;
}

.sick-leave-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sick-leave-text {
    font-size: 14px;
    line-height: 16px;
    margin: 4px 0;
    text-align: left;
}

.sick-leave-item {
    --bar-width: 4px;
}

.sick-leave-item-number {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    width: 100%;
    margin: 4px 0;
}

.sick-leave-item-row {
    display: grid;
    justify-content: start;
    column-gap: 10px;
    grid-auto-flow: column;
    align-items: center;
}

.sick-leave-blue-bar {
    width: var(--bar-width);
    height: 100%;
    background-color: var(--dashboard-blue-purple);
}

.sick-leave-gray-bar {
    width: var(--bar-width);
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

#sick-leave-input {
    background-color: white;
    width: 70px;
    height: 20px;
    color: black !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    background: var(--dashboard-ice-white);
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
    border-radius: var(--dashboard-border-radius);
    margin-right: 10px;
}