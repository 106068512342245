.number-input-container {
    --input-height: 2.5rem;
    height: var(--input-height);
    display: flex;
    align-items: center;
    width: inherit;
}

.number-symbol {
    height: var(--input-height);
    width: var(--input-height);
    display: inline-block;
    text-align: center;
    background-color: white;
    color: var(--background-color);
    font-size: 16px;
    line-height: calc(var(--input-height) - 2px);
    font-weight: 900;
    padding-left: 5px;
    border-radius: 10px 0px 0px 10px;
    border: 2px solid white;
}

.number-symbol-edit {
    height: var(--input-height);
    display: inline-block;
    background-color: white;
    color: var(--background-color);
    font-size: 16px;
    line-height: calc(var(--input-height) - 2px);
    font-weight: bolder;
}

.number-input {
    background-color: var(--background-color-faded);
    border: 2px solid white;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    border-radius: 0px 10px 10px 0px;
    border-spacing: 0;
    height: var(--input-height);
    color: white;
    padding: 0 10px;
    font-weight: bold;
    line-height: var(--input-height);
    font-size: 22px;
    width: 12vw;
    padding-top: 0px;
    padding-bottom: 0px;
}

.number-input-edit {
    background-color: var(--background-color-faded);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    height: var(--input-height);
    color: slategray;
    font-weight: bolder;
    line-height: var(--input-height);
    font-size: 1.25rem;
    width: 8rem;
    text-align: right;
}

.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.number-input::placeholder {
    color: var(--dark-background-color-faded);
}

.number-input:focus {
    outline: none;
}

.number-project-button {
    margin-left: 20px;
    background: var(--dark-background-color);
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    border: none;
    font-weight: bold;
}

.number-project-button-edit {
    margin-left: 20px;
    background: var(--dark-background-color);
    color: var(--dashboard-blue);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    border: none;
    font-weight: bold;
}

.number-input-slider {
    width: 30vw !important;
    color: white !important;
    margin-top: 30px;
}

#date-picker-dialog-edit {
    color: slategray;
}

.error {
    width: 100%;
    font-size: 1rem;
    color: red;
}

@media (max-width: 900px) {
    .number-input-container {
        height: auto;
        width: inherit;
    }

    .number-input {
        width: 80%;
    }

    .number-symbol {
        line-height: calc(var(--input-height));
    }
}