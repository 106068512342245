.forecasting-loader-background {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forecasting-loader-container {
    background-color: white;
    height: 35vh;
    width: 40vw;
    box-sizing: border-box;
    padding: 20px 40px;
    border-radius: 20px;
}

.forecasting-loader-text {
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 40px;
}