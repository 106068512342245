.approve-reject-buttons {
    display: flex;
    width: 100%;
    padding: 0 5rem;
    justify-content: space-evenly;
}
.approve-reject-buttons button {
    margin: 0 1rem;
}

.approve-reject-buttons .org-user-form-action-button.approve-button {
    background: var(--emerald-green-faded);
}

.approve-reject-buttons .org-user-form-action-button.reject-button {
    background: #e74c3c;
}
