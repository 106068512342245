.date-picker {
    color: white !important;
}

.date-picker {
    color: var(--dashboard-blue) !important;
}

#date-picker-dialog-helper-text {
    color: white;
    padding: 3px;
}

#date-picker-dialog-label {
    color: white;
    display: none;
}

#date-picker-dialog {
    font-size: 24px;
    color: white;
}

#date-picker-dialog-edit{
    font-weight: bolder;
    font-size: 1.25rem;
    color: slategrey;
}

.MuiInput-underline:after {
    border-bottom: var(--dark-background-color) !important;
}