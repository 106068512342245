.terms-of-service-background {
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.terms-of-service-container {
    background-color: white;
    border-radius: 0.25rem;
    color: black;
    padding: 0.5rem 1rem;
    max-width: 75vw;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
}

.terms-of-service-text {
    overflow-y: scroll;
}

.terms-accept-button {
    background-color: var(--blue-purple);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    margin: 20px auto;
    font-weight: bold;
    border-radius: 5px;
}

.terms-of-service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
}

.terms-of-service-header > svg {
    font-size: 2rem;
    cursor: pointer;
}