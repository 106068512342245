.tax-deferred-sub-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
}

.tax-deferred-content {
    display: grid;
    row-gap: 20px;
}

.tax-deferred-number-container {
    display: grid;
    row-gap: 10px;
}