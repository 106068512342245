.auth-page-container {
    display: grid;
    grid-template-columns: 35% 65%;
    height: 100vh;
    grid-auto-flow: column;
}

.auth-form-container {
    padding: 4rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: scroll !important;
}

@media (max-width: 1024px) {
    .auth-page-container {
        display: block;
        overflow-y: scroll !important;
    }

    .auth-form-container {
        padding: 2rem;
        overflow-y: visible !important;
    }
}

.auth-form-content {
    width: 100%;
}

.auth-form-title {
    font-size: 1.5em;
}

.auth-form-title-underline {
    background: #396afc;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2948ff, #396afc);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2948ff, #396afc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 60px;
    height: 5px;
    border-radius: 3px;
    margin-bottom: 20px;
}

.auth-form-error {
    color: #e74c3c;
    font-size: 0.8em;
}

.forgot-password-button {
    margin-top: 30px;
    text-decoration: underline;
    cursor: pointer;
}