.forecasting-background-container {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

.forecasting-container {
    background-color: white;
    height: 100vh;
    overflow-y: scroll;
    width: 40vw;
    animation: 0.5s ease-out 0s 1 slideInFromLeft;
    box-sizing: border-box;
    padding: 2.5rem;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
}

.forecasting-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.forecasting-header > svg {
    margin-left: 1.5rem;
    cursor: pointer;
}