.app-alert-container {
    position: absolute;
    top: 5%;
    left: 50%;
    background-color: white;
    border-radius: 5px;
    transform: translate(-50%, 0%);
    z-index: 10000;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 40%;
    animation: fade 0.4s linear;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.app-alert-error {
    border-left: 15px solid #e74c3c;
    color: #e74c3c;
}

.app-alert-success {
    border-left: 15px solid var(--emerald-green);
    color: var(--emerald-green);
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

.app-alert-icon {
    margin: 0 auto;
}

.app-alert-text-container {
    margin-left: 20px;
    flex-grow: 2;
}

.app-alert-title {
    text-align: left;
    font-size: 1.2em;
    color: black;
    margin: 0;
    margin-bottom: 5px;
}

.app-alert-text {
    margin: 0;
    text-align: left;
    font-size: 1em;
    font-weight: 400;
    color: black;
}