.forecasting-form-container {
    margin: 30px 0;
}

.forecasting-no-option-selected {
    text-align: center;
    width: 100%;
}

.forecasting-sub-button-container {
    margin: 30px 0;
}

.forecasting-sub-button {
    background-color: var(--blue-purple);
    outline: none;
    margin-right: 12px;
    border: none;
    cursor: pointer;
    color: white;
    width: 30%;
    height: 35px;
    border-radius: 6px;
    font-weight: 500;
}

.forecasting-sub-button-outlined {
    background-color: var(--light-white);
    border: 2px solid var(--blue-purple);
    color: var(--blue-purple);
}

.warning-message-title {
    background-color: var(--yellow);
    color: black;
    display: inline-block;
    padding: 5px 10px;
    margin: 0;
    border-radius: 5px;
}

.warning-message-text {
    font-size: 0.8rem;
}

.forecasting-select {
    width: 100%;
    background-color: var(--dashboard-ice-white);
    color: black;
    border-radius: var(--dashboard-border-radius);
}

.forecast-select-label {
    font-weight: normal;
    margin: 0;
    font-weight: bold;
    font-size: 16px;
}

.forecast-input-container {
    display: grid;
    row-gap: 30px;
    grid-template-columns: 100%;
    width: 100%;
}

.forecast-form-section {
    width: 100%;
    display: grid;
    row-gap: 20px;
    background-color: var(--dashboard-ice-white);
    padding: 25px;
    grid-template-columns: 100%;
    box-sizing: border-box;
    width: 100%;
}