.menu {
    position: absolute;
    background-color: var(--dashboard-ice-white);
    width: var(--menu-width);
    padding: 0;
    height: 100vh;
    top: 0;
    left: 0;
}

@media (max-width:900px) {
    .menu {
        width: auto;
    }
}
