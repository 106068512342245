.state-section-container {
    display: flex;
}

.rsm-marker > text {
    fill: white !important;
}

.default {
    fill: #8CA4E7 !important;
}

.no-state-tax {
    fill: rgb(115, 93, 198);
}

.no-pension-tax {
    fill: rgb(93, 165, 198);
}

.map-tooltip-section {
    text-align: left;
}

.map-tooltip-section > h1 {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
}

[d^="M62.29355114580346"] {
    fill: var(--dashboard-blue-purple) !important;
}

.state-map-chart-container {
    width: 90%;
    margin: 0 auto;
    margin-top: -15px;
    margin-bottom: -30px;
}

.state-item-container {
    display: grid;
    row-gap: 25px;
    margin-top: 40px;
}

.state-section-container {
    display: grid;
    row-gap: 25px;
}

.state-left-section {
    height: 100%;
}

.state-text {
    font-size: 14px;
    line-height: 16px;
    margin: 4px 0;
    text-align: left;
}

.state-item {
    --bar-width: 4px;
}

.state-item-number {
    font-size: 20px;
    line-height: 22px;
    font-weight: bold;
    width: 100%;
    margin: 4px 0;
}

.state-item-row {
    display: grid;
    justify-content: start;
    column-gap: 10px;
    grid-auto-flow: column;
    align-items: center;
}

.state-blue-bar {
    width: var(--bar-width);
    height: 100%;
    background-color: var(--dashboard-blue-purple);
}

.state-gray-bar {
    width: var(--bar-width);
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}