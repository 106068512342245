.admin-pagination-controls-container {
    margin: 12px 0;
    margin-top: 30px;
}

.admin-pagination-controls-button {
    padding: 6px;
    color: var(--admin-text);
    width: 35px;
    height: 35px;
    font-weight: bold;
    border-radius: 5px;
    border: none;
    margin-right: 8px;
    background-color: white;
}

.admin-pagination-controls-button:hover {
    transform: scale(1.01);
    transition: 0.2s;
    color: white;
    background-color: var(--blue-purple);
    cursor: pointer;
}

.admin-pagination-controls-button:focus {
    outline: none;
}

.admin-pagination-controls-button-active {
    color: white;
    background-color: var(--blue-purple);
}

.admin-pagination-controls-button-arrow {
    color: black;
    width: 35px;
    height: 35px;
    border: none;
    background-color: transparent;
}

.admin-pagination-controls-button-arrow:hover {
    transform: scale(1.01);
    transition: 0.2s;
    cursor: pointer;
}

.admin-pagination-controls-button-arrow:focus {
    outline: none;
}

.admin-pagination-controls-button-arrow:last-of-type {
    margin-left: -8px;
}