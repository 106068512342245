.auto-complete-option {
    background-color: white;
    color: var(--background-color);
    font-weight: 800;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 12px;
    width: calc(70vw);
    border: none;
    text-align: left;
}

.auto-complete-option:focus {
    outline: none;
}

.auto-complete-option:hover {
    cursor: pointer;
    background-color: var(--background-color-faded);
    color: white;
}

.active {
    background-color: var(--progress-bar-background);
    color: white;
}