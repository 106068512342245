.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}

.cell-editable {
    cursor: cell;
}

.MuiDataGrid-root .MuiDataGrid-cell:not(.cell-editable):focus, .MuiDataGrid-root .MuiDataGrid-cell:not(.cell-editable):focus-within {
    outline: none !important;
}