.raise-widget-container {
    display: grid;
    row-gap: 20px;
}

.raise-item-container {
    display: grid;
    row-gap: 20px;
}

.raise-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(85, 121, 228, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: var(--dashboard-border-radius);
    padding: 15px 20px;
}

.raise-item-right {
    font-style: normal;
    font-size: 18px;
}

.raise-item-left {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #5579E4;
}