.super-admin-page-background {
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow-y: scroll;
}

.super-admin-page-container {
    width: 90%;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.super-admin-org-card {
    background-color: white;
    padding: 25px 35px;
    width: 100%;
    align-items: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.super-admin-card-container {
    display: grid;
    row-gap: 20px;
    margin-top: 30px;
    width: 100%;
}

.super-admin-org-card-name {
    font-weight: bold;
    font-size: 20px;
}

.super-admin-inherit-button {
    background-color: #8E2DE2;
    color: white;
    border: none;
    font-weight: bold;
    padding: 10px 40px;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    cursor: pointer;
}

.super-admin-inherit-button:hover {
    opacity: 0.9;
}