.overview-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overview-header-button-container {
    display: grid;
    column-gap: 0.75rem;
    grid-auto-flow: column;
}

.overview-header-button {
    background: var(--dashboard-blue);  /* fallback for old browsers */
    color: white;
    text-transform: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
    border-radius: var(--dashboard-border-radius);
    text-align: left;
    display: flex;
    align-items: center;
}

.overview-header-button-mobile {
    background: white;  /* fallback for old browsers */
    color: black;
    padding: 0;
}

.overview-header-title-container {
    row-gap: 1rem;
    margin: 1.25rem 0;
}

@media (max-width: 900px) {
    .overview-header-text-container > h1 {
        display: none;
    }
}

.overview-header-image-container {
    max-height: 4rem;
    max-width: 4rem;
    display: inline-block;
    margin-right: 0.5rem;
}

.overview-header-text-container {
    display: inline-block;
    height: 100%;
}

.overview-header-text-container > h1 {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    color: #000000;
}

.overview-header-title-container > p {
    margin: 0 !important;
}

.overview-header-mode-button {
    background-color: var(--dashboard-ice-white) !important;
    border: 2px solid var(--dashboard-blue-purple);
    color: var(--dashboard-blue-purple);
}

.overview-header-tutorial-button {
    background-color: var(--dashboard-blue);
    z-index: 99;
    color: white;
    border: none;
    position: fixed;
    vertical-align: top;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    top: 150px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    right: -50px;
    -moz-transform:rotate(-90deg);
    -ms-transform:rotate(-90deg);
    -o-transform:rotate(-90deg);
    -webkit-transform:rotate(-90deg);
}

.overview-forecasting-warning {
    color: var(--yellow);
    font-weight: bold;
    font-size: 28px;
    margin-left: 10px;
    border-radius: var(--dashboard-border-radius);
}

.MuiToggleButton-root:not(.tb-reset).Mui-selected {
    color: white !important;
    background-color: var(--dashboard-blue) !important;
}

.MuiToggleButton-root:not(.tb-reset) {
    width: 115px !important;
    padding: 12.5px 15px !important;
    height: auto !important;
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 12px !important;
}

.MuiToggleButtonGroup-root {
    border-radius: var(--dashboard-border-radius) !important;
}