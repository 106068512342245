.user-page {
    width: 100vw;
    height: 100vh;
    --menu-width: 5rem;
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

.user-page-content-container {
    margin-left: calc(var(--menu-width) + 3vw);
    margin-right: 3vw;
}

.user-page-title {
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
    color: #000000;
    margin: 2rem 0;
}

@media (max-width:900px) {
    .user-page-content-container {
        margin: 1.25rem;
    }

    .user-page-title {
        margin: 0.75rem;
    }
}