.org-page-title {
    font-size: 1.7rem;
    margin: 0;
    font-weight: 600;
}

.org-page-subtitle {
    margin: 10px 0;
    font-size: 1rem;
    color: #7f8fa6;
    font-weight: 700;
    margin-bottom: 30px;
}