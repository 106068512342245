.compare-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.overview-header-button-container {
    display: grid;
    column-gap: 10px;
    grid-auto-flow: column;
}

.overview-header-button {
    background: var(--dashboard-blue);  /* fallback for old browsers */
    color: white;
    border: none;
    cursor: pointer;
    padding: 12.5px 30px;
    font-size: 12px;
    font-weight: bold;
    border-radius: var(--dashboard-border-radius);
    margin-right: 0.25rem;
}

.overview-header-mode-button {
    background-color: var(--dashboard-ice-white) !important;
    border: 2px solid var(--dashboard-blue-purple);
    color: var(--dashboard-blue-purple);
}

.compare-legend {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: 49.25% 49.25%;
    justify-content: space-between;
    margin-bottom: 30px;
}

.compare-legend-item {
    display: grid;
    column-gap: 20px;
    grid-auto-flow: column;
    justify-content: center;
    background-color: var(--dashboard-blue-purple);
    color: white;
    padding: 15px 20px;
    width: 100%;
    border-radius: var(--dashboard-border-radius);
    font-weight: bold;
}

.compare-legend-item-forecast {
    background-color: var(--yellow) !important;
}