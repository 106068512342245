.create-account-textfield {
    border: 1.5px solid lightgray;
    width: 100%;
    flex: 1 1;
    height: 3rem;
    border-radius: 6px;
    margin: 0.5rem 0;
    padding-left: 20px;
    box-sizing: border-box;
}

.create-account-textfield-error {
    border: 1.5px solid #e74c3c;
}