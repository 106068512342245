.summary-widget-container {
    background: var(--dashboard-light-purple) !important;
}

.overview-status-title {
    font-size: 0.9rem;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
}

.overview-status-number {
    display: flex;
    align-items: center;
    font-size: 1rem;
    text-align: left;
    text-transform: capitalize;
}

.overview-status-number > span {
    font-size: 2rem;
    display: inline-block;
    text-transform: uppercase;
    margin-right: 10px;
    color: var(--dashboard-blue-purple);
}

@media (max-width: 900px) {
    .overview-status-number {
        font-size: 0.7rem;
    }

    .overview-status-number > span {
        font-size: 1.5rem;
    }
}

.overview-status-total {
    font-weight: bold;
}
.overview-status-total > span {
    color: #000;
}

.summary-section {
    display: grid;
    row-gap: 1.25rem;
}

.summary-section-container {
    display: grid;
    row-gap: 1rem;
}