.settings-content {
    margin-top: 2.5rem;
    display: flex;
}

.settings-left {
    flex: 1 1;
    display: grid;
    row-gap: 1rem;
    padding: 1rem;
}

.change-password-form {
    flex: 1 1;
    background-color: var(--dashboard-ice-white);
    padding: 1rem;
    display: grid;
    row-gap: 1rem;
    height: fit-content;
}

.settings-field > h1 {
    font-size: 0.9rem;
    margin: 5px 0;
}

.settings-field > input {
    margin: 1rem 0;
    min-width: 100%;
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 400;
    padding: 1rem;
    background-color: var(--dashboard-light-purple);
    color: black;
    border-radius: 0.5rem;
    display: block;
    border: 2px solid transparent;
    box-sizing: border-box;
}

.settings-field > input:focus {
    outline: none;
    border: 2px solid var(--dashboard-blue-purple);
}

.change-password-form > h1 {
    margin: 0;
    font-size: 1.5rem;
}

.change-password-form > input {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 400;
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
}

.change-password-form > input:focus {
    outline: none;
    border: 2px solid var(--dashboard-blue-purple);
}

.settings-left > button {
    cursor: pointer;
    width: 100%;
    display: block;
    font-size: 1.25rem;
    background-color: var(--dashboard-blue-purple);
    color: white;
    height: 3rem;
    font-weight: 700;
    border-radius: 0.5rem;
    border: none;
}

.change-password-form > button {
    cursor: pointer;
    width: 100%;
    display: block;
    font-size: 1.25rem;
    background-color: var(--dashboard-blue-purple);
    color: white;
    height: 3rem;
    font-weight: 700;
    border-radius: 0.5rem;
    border: none;
}

@media (max-width:900px) {
    .settings-content {
        flex-direction: column;
        margin-top: 0.5rem;
    }

    .settings-field > input {
        margin: 0.25rem 0;
        font-size: 1rem;
    }
}
