.compare-card-item {
    width: 100%;
    position: relative;
}

.compare-card-container {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-columns: 49.25% 49.25%;
    justify-content: space-between;
}

.compare-card-container-column {
    display: flex;
    flex-direction: column;
    grid-template-columns: 100%;
    row-gap: 20px;
}

.compare-icon {
    top: -10px;
    right: -5px;
    position: absolute;
    color: white;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.compare-eyes {
    background-color: var(--yellow);
}

.compare-lock {
    background-color: var(--blue-purple);
}

.compare-card-item-forecast-content > div {
    background-color: rgba(241,196,15,0.125) !important;
}
