.write-in-input {
    background-color: transparent;
    border: 0px;
    border-bottom: 2px solid white;
    color: white;
    font-size: 1.8em;
    padding: 6px 0px;
    width: 70vw;
}

.write-in-input:focus {
    outline: none;
}

.write-in-input::placeholder {
    color: var(--dark-background-color-faded);
}

.write-in-input::-webkit-outer-spin-button,
.write-in-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.write-in-options-container {
    margin-top: 20px;
}